import React, {useContext, useState, useEffect} from "react";
import {Context} from "..";
import {cancelPosting, getPostings} from "../http/userAPI";
import {Row, Table} from "react-bootstrap";
import {LOGIN_ROUTE, POSTING_ROUTE, PRODUCT_ROUTE} from "../utils/const";
import {Button} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {observer} from "mobx-react-lite";

const History = observer(() => {
    const {user} = useContext(Context)
    const {product} = useContext(Context)
    const [activePostings, setActivePostings] = useState([])
    const [deliveredPostings, setDeliveredPostings] = useState([])
    const navigate = useNavigate()

    useEffect(() => {
        getPostings(user, true).then(data => setActivePostings(data))
        getPostings(user, false).then(data => setDeliveredPostings(data))
    }, [product.reload])

    return (
        <div>
            <h1>История заказов</h1>
            <ul className="nav nav-tabs mt-4" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="active-tab" data-bs-toggle="tab"
                            data-bs-target="#active-tab-pane"
                            type="button" role="tab" aria-controls="active-tab-pane" aria-selected="true">Активные
                        заказы
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="delivered-tab" data-bs-toggle="tab"
                            data-bs-target="#delivered-tab-pane"
                            type="button" role="tab" aria-controls="delivered-tab-pane"
                            aria-selected="false">Закрытые заказы
                    </button>
                </li>
            </ul>
            <div className="tab-content mt-4" id="myTabContent">
                <div className="tab-pane fade show active" id="active-tab-pane" role="tabpanel"
                     aria-labelledby="active-tab"
                     tabIndex="0">
                    {activePostings.length > 0 && (
                        <Table>
                            <thead>
                            <tr>
                                <th>Номер заказа</th>
                                <th>Товар</th>
                                <th>Количество</th>
                                <th>Статус</th>
                                <th>Адресс</th>
                                <th>Дата создания</th>
                                <th></th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {activePostings.map((posting) => (
                                posting.products.length > 0 &&
                                <>
                                    <tr>
                                        <td rowSpan={posting.products.length}>{posting.number}</td>
                                        <td>{posting.products[0].article}</td>
                                        <td>{posting.products[0].count}</td>
                                        <td rowSpan={posting.products.length}>{posting.status}</td>
                                        <td rowSpan={posting.products.length}>{posting.address}</td>
                                        <td rowSpan={posting.products.length}>{posting.date}</td>
                                        {(posting.status === 'Необходимо отгрузить' || posting.status === 'Необходимо обработать') &&
                                            <>
                                                <td rowSpan={posting.products.length}>
                                                    <Button variant="warning" onClick={() => {
                                                        navigate(POSTING_ROUTE + '/' + posting.number)
                                                    }}>
                                                        Изменить
                                                    </Button>
                                                </td>
                                                <td rowSpan={posting.products.length}>
                                                    <Button onClick={() => {
                                                        cancelPosting(user, posting.number).then(r => product.setReload(product.reload + 1))
                                                        // navigate(POSTING_ROUTE + '/' + posting.number)
                                                    }}>
                                                        Отменить
                                                    </Button>
                                                </td>
                                            </>
                                        }

                                    </tr>
                                    {posting.products.slice(1).map((product) => (
                                        <tr>
                                            <td> {product.article}</td>
                                            <td> {product.count}</td>
                                        </tr>
                                    ))}
                                </>
                            ))}
                            </tbody>
                        </Table>
                    )}
                    {activePostings.length === 0 && (<p>У вас нет активных заказов</p>)}
                </div>
                <div className="tab-pane fade" id="delivered-tab-pane" role="tabpanel" aria-labelledby="delivered-tab"
                     tabIndex="1">
                    {deliveredPostings.length > 0 && (
                        <Table>
                            <thead>
                            <tr>
                                <th>Номер заказа</th>
                                <th>Товар</th>
                                <th>Количество</th>
                                <th>Статус</th>
                                <th>Адресс</th>
                                <th>Дата создания</th>
                            </tr>
                            </thead>
                            <tbody>
                            {deliveredPostings.map((posting) => (
                                posting.products.length > 0 &&
                                <>
                                    <tr>
                                        <td rowSpan={posting.products.length}>{posting.number}</td>
                                        <td>{posting.products[0].article}</td>
                                        <td>{posting.products[0].count}</td>
                                        <td rowSpan={posting.products.length}>{posting.status}</td>
                                        <td rowSpan={posting.products.length}>{posting.address}</td>
                                        <td rowSpan={posting.products.length}>{posting.date}</td>
                                    </tr>
                                    {posting.products.slice(1).map((product) => (
                                        <tr>
                                            <td> {product.article}</td>
                                            <td> {product.count}</td>
                                        </tr>
                                    ))}
                                </>
                            ))}
                            </tbody>
                        </Table>
                    )}
                    {deliveredPostings.length === 0 && (<p>У вас нет закрытых заказов</p>)}
                </div>
            </div>
        </div>
    );
});

export default History;