import React, {useContext, useState, useEffect} from "react";
import {Form, Button, Container, FloatingLabel} from "react-bootstrap";
import {Context} from "../index";
import {createDelivering} from "../http/productAPI";
import {CATALOG_ROUTE} from "../utils/const";
import {useNavigate} from "react-router-dom";
import {getStore, getStores} from "../http/userAPI";
import { AddressSuggestions } from 'react-dadata';
import 'react-dadata/dist/react-dadata.css';

const ToDelivering = () => {
    const navigate = useNavigate()
    const {product} = useContext(Context)
    const {user} = useContext(Context)
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [phone, setPhone] = useState('')
    const [address, setAddress] = useState({value: "Волгоград, ", data: {geo_lat: 0.0, geo_lon: 0.0}})
    const [price, setPrice] = useState('')
    const [comment, setComment] = useState('')

    const [stores, setStores] = useState([])
    const [storeId, setStoreId] = useState({id: 'c68d733c-e253-11ec-0a80-01540008c7ec'})

    useEffect(() => {
        getStores(user).then(data => setStores(data))
        getStore(user).then(data => setStoreId(data))
    }, [])

    const click = async () => {
        try {
            let data = await createDelivering(user.user, product.basketProducts, firstName, lastName, phone, address.value, address.data.geo_lat, address.data.geo_lon, price, comment, storeId);
            console.log(data)
            if (data.status === 200) {
                product.setBasketProducts([])
                navigate(CATALOG_ROUTE)
            }
        } catch (e) {
            alert(e.response.data?.message)
        }
    }

    return (
        <div>
            <Container style={{"max-width": "960px"}}>
                <main>
                    <div className="py-5 text-center">
                        <h2>Оформление доставки до покупателя</h2>
                    </div>

                    <div className="row g-5">
                        <div className="col-md-5 col-lg-4 order-md-last">
                            <h4 className="d-flex justify-content-between align-items-center mb-3">
                                <span className="text-primary">Товары в заказе</span>
                                <span className="badge bg-primary rounded-pill">{product.basketProducts?.length}</span>
                            </h4>
                            <ul className="list-group mb-3">
                                {product.basketProducts?.map(product =>
                                    <li className="list-group-item d-flex justify-content-between lh-sm">
                                        <div>
                                            <h6 className="my-0">{product.name}</h6>
                                            {/*<small className="text-body-secondary">{product.price_opt + ' р'}</small>*/}
                                        </div>
                                        <span className="text-body-secondary">{product.count + 'шт'}</span>
                                    </li>
                                )}

                                {/*<li className="list-group-item d-flex justify-content-between">*/}
                                {/*    <span>Итого</span>*/}
                                {/*    <strong>{product.basketSum + ' р'}</strong>*/}
                                {/*</li>*/}
                            </ul>
                        </div>

                        <div className="col-md-7 col-lg-8">
                            <h4 className="mb-3">Информация получателя</h4>
                            <Form className="needs-validation" noValidate>
                                <div className="row g-3">
                                    <Form.Group className="col-sm-6" controlId="firstName">
                                        <Form.Label>Имя получателя</Form.Label>
                                        <Form.Control type="text" id="firstName" value={firstName} onChange={e => setFirstName(e.target.value)} />
                                    </Form.Group>

                                    <Form.Group className="col-sm-6" controlId="lastName">
                                        <Form.Label>Фамилия получателя</Form.Label>
                                        <Form.Control type="text" id="lastName" value={lastName} onChange={e => setLastName(e.target.value)} />
                                    </Form.Group>

                                    <Form.Group className="col-12" controlId="phone">
                                        <Form.Label>Телефон получателя</Form.Label>
                                        <Form.Control type="text" id="phone" value={phone} onChange={e => setPhone(e.target.value)} />
                                    </Form.Group>

                                    <Form.Group className="col-12" controlId="address">
                                        <Form.Label>Адресс получателя</Form.Label>
                                        <AddressSuggestions token="a199d8110c08dd0d938a91b102aefa65fcf11239" value={address} onChange={setAddress} />
                                        {/*<Form.Control type="text" id="address" value={address} onChange={e => setAddress(e.target.value)} />*/}
                                    </Form.Group>

                                    <Form.Group className="col-12" controlId="price">
                                        <Form.Label>Стоимость доставки</Form.Label>
                                        <Form.Control type="text" id="price" value={price} onChange={e => setPrice(e.target.value)} />
                                    </Form.Group>

                                    <Form.Group className="col-12" controlId="comment">
                                        <Form.Label>Комментарий</Form.Label>
                                        <Form.Control type="textarea" id="comment" value={comment} onChange={e => setComment(e.target.value)} />
                                    </Form.Group>

                                    {/*<hr className="my-4"/>*/}

                                    {/*<Form.Check // prettier-ignore*/}
                                    {/*    type="switch"*/}
                                    {/*    label="С НДС"*/}
                                    {/*    id="disabled-custom-switch"*/}
                                    {/*    value={nds}*/}
                                    {/*/>*/}

                                    {/*<hr className="my-4"/>*/}

                                    {/*<h4 className="mb-3">Оплата</h4>*/}

                                    {/*<Form.Group controlId="payment">*/}
                                    {/*    <Form.Check*/}
                                    {/*        value="cash"*/}
                                    {/*        type="radio"*/}
                                    {/*        aria-label="radio 1"*/}
                                    {/*        label="Наличный расчет (При получении)"*/}
                                    {/*        // onChange={handleChange}*/}
                                    {/*        checked={payment === "design"}*/}
                                    {/*    />*/}
                                    {/*    <Form.Check*/}
                                    {/*        value="card"*/}
                                    {/*        type="radio"*/}
                                    {/*        aria-label="radio 2"*/}
                                    {/*        label="Банковская карта +3% (При получении)"*/}
                                    {/*        // onChange={handleChange}*/}
                                    {/*        checked={payment === "food"}*/}
                                    {/*    />*/}
                                    {/*    <Form.Check*/}
                                    {/*        value="checking_account"*/}
                                    {/*        type="radio"*/}
                                    {/*        aria-label="radio 2"*/}
                                    {/*        label="Выставить счет"*/}
                                    {/*        // onChange={handleChange}*/}
                                    {/*        checked={payment === "food"}*/}
                                    {/*    />*/}
                                    {/*</Form.Group>*/}

                                </div>

                                <hr className="my-4"/>

                                <FloatingLabel controlId="floatingInputGrid" className="h6" label="Оформить перемещение на">
                                    <Form.Select aria-label="Оформить перемещение на" value={storeId.id}
                                                 onChange={(e) => {
                                                     setStoreId({id: e.target.value})
                                                 }}>
                                        {stores.map((entry) =>
                                            <option value={entry.id} key={entry.id}>{entry.name}</option>
                                        )}
                                    </Form.Select>
                                </FloatingLabel>

                                <Button
                                    className="w-100 btn btn-primary btn-lg"
                                    // variant={"outline-success"}
                                    onClick={click}
                                >
                                    Оформить доставку
                                </Button>
                            </Form>
                        </div>
                    </div>
                </main>
            </Container>
        </div>
    );
};

export default ToDelivering;