import React, {useContext, useEffect, useState} from 'react';
import {BrowserRouter} from "react-router-dom";
import AppRouter from "./components/AppRouter";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import {observer} from "mobx-react-lite";
import {Context} from "./index";
import {Spinner} from "react-bootstrap";

const App = observer(() => {
    const {user} = useContext(Context)
    useEffect(() => {

    }, [])

    return (
        <BrowserRouter>
            {user.token && <NavBar/>}
            <AppRouter/>
            {user.token && <Footer/>}
        </BrowserRouter>
    );
});

export default App;
