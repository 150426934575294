import React, {useContext, useState} from "react";
import {InputGroup, Form, Button, Container, FloatingLabel} from "react-bootstrap";
import {Context} from "../index";
import {login} from "../http/userAPI";
import {CATALOG_ROUTE} from "../utils/const";
import {createPosting} from "../http/productAPI";
import {useNavigate} from "react-router-dom";
import {AddressSuggestions} from "react-dadata";

const Checkout = () => {
    const navigate = useNavigate()
    const {product} = useContext(Context)
    const {user} = useContext(Context)
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [phone, setPhone] = useState('')
    const [address, setAddress] = useState({value: user.user.address, data: {geo_lat: user.user.latitude, geo_lon: user.user.longitude}})
    const [price, setPrice] = useState('')
    const [comment, setComment] = useState('')
    const [nds, setNds] = useState('')
    const [payment, setPayment] = useState('')

    const click = async () => {
        try {
            let data = await createPosting(user.user, product.basketProducts, firstName, lastName, phone, address, price, comment, nds, payment);
            if (data.status == 200) {
                product.setBasketProducts([])
                product.setBasketSum(0)
                navigate(CATALOG_ROUTE)
            }
            // user.setUser(data)
            // user.setIsAuth(true)
            // user.setToken(data.token)
            // navigate(CATALOG_ROUTE)
        } catch (e) {
            alert(e.response.data?.message)
        }

    }

    return (
        <div>
            <Container style={{"max-width": "960px"}}>
                <main>
                    <div className="py-5 text-center">
                        <h2>Оформление заказа</h2>
                        {/*<p className="lead">Below is an example form built entirely with Bootstrap’s form controls.*/}
                        {/*    Each required form group has a validation state that can be triggered by attempting to*/}
                        {/*    submit the form without completing it.</p>*/}
                    </div>

                    <div className="row g-5">
                        <div className="col-md-5 col-lg-4 order-md-last">
                            <h4 className="d-flex justify-content-between align-items-center mb-3">
                                <span className="text-primary">Ваш заказ</span>
                                <span className="badge bg-primary rounded-pill">{product.basketProducts?.length}</span>
                            </h4>
                            <ul className="list-group mb-3">
                                {product.basketProducts?.map(product =>
                                    <li className="list-group-item d-flex justify-content-between lh-sm">
                                        <div>
                                            <h6 className="my-0">{product.name}</h6>
                                            <small className="text-body-secondary">{product.price_opt + ' р'}</small>
                                        </div>
                                        <span className="text-body-secondary">{product.count + 'шт'}</span>
                                    </li>
                                )}

                                {/*<li className="list-group-item d-flex justify-content-between bg-body-tertiary">*/}
                                {/*    <div className="text-success">*/}
                                {/*        <h6 className="my-0">Promo code</h6>*/}
                                {/*        <small>EXAMPLECODE</small>*/}
                                {/*    </div>*/}
                                {/*    <span className="text-success">−$5</span>*/}
                                {/*</li>*/}
                                <li className="list-group-item d-flex justify-content-between">
                                    <span>Итого</span>
                                    <strong>{product.basketSum + ' р'}</strong>
                                </li>
                            </ul>

                            {/*<InputGroup className="mb-3">*/}
                            {/*    <Form.Control*/}
                            {/*        className="form-control"*/}
                            {/*        placeholder="Promo code"*/}
                            {/*        // value={promo}*/}
                            {/*        // onChange={e => setEmail(e.target.value)}*/}
                            {/*        aria-describedby="button-promo"*/}
                            {/*    />*/}
                            {/*    <Button variant="outline-secondary" id="button-promo">*/}
                            {/*        Redeem*/}
                            {/*    </Button>*/}
                            {/*</InputGroup>*/}
                        </div>

                        <div className="col-md-7 col-lg-8">
                            <h4 className="mb-3">Информация получателя</h4>
                            <Form className="needs-validation" noValidate>
                                <div className="row g-3">
                                    <Form.Group className="col-sm-6" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Имя получателя</Form.Label>
                                        <Form.Control type="text" id="firstName" value={firstName} onChange={e => setFirstName(e.target.value)} />
                                    </Form.Group>

                                    <Form.Group className="col-sm-6" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Фамилия получателя</Form.Label>
                                        <Form.Control type="text" id="lastName" value={lastName} onChange={e => setLastName(e.target.value)} />
                                    </Form.Group>

                                    <Form.Group className="col-12" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Телефон получателя</Form.Label>
                                        <Form.Control type="text" id="phone" value={phone} onChange={e => setPhone(e.target.value)} />
                                    </Form.Group>

                                    <Form.Group className="col-12" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Адресс получателя</Form.Label>
                                        <AddressSuggestions token="a199d8110c08dd0d938a91b102aefa65fcf11239" value={address} onChange={setAddress} />
                                        {/*<Form.Control type="text" id="address" value={address} onChange={e => setAddress(e.target.value)} />*/}
                                    </Form.Group>

                                    <Form.Group className="col-12" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Цена для получателя</Form.Label>
                                        <Form.Control type="text" id="price" value={price} onChange={e => setPrice(e.target.value)} />
                                    </Form.Group>

                                    <Form.Group className="col-12" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Комментарий</Form.Label>
                                        <Form.Control type="textarea" id="comment" value={comment} onChange={e => setComment(e.target.value)} />
                                    </Form.Group>

                                    <hr className="my-4"/>

                                    <Form.Check // prettier-ignore
                                        type="switch"
                                        label="С НДС"
                                        id="disabled-custom-switch"
                                        value={nds}
                                    />

                                    <hr className="my-4"/>

                                    <h4 className="mb-3">Оплата</h4>

                                    <Form.Group controlId="payment">
                                        <Form.Check
                                            value="cash"
                                            type="radio"
                                            aria-label="radio 1"
                                            label="Наличный расчет (При получении)"
                                            // onChange={handleChange}
                                            checked={payment === "design"}
                                        />
                                        <Form.Check
                                            value="card"
                                            type="radio"
                                            aria-label="radio 2"
                                            label="Банковская карта +3% (При получении)"
                                            // onChange={handleChange}
                                            checked={payment === "food"}
                                        />
                                        <Form.Check
                                            value="checking_account"
                                            type="radio"
                                            aria-label="radio 2"
                                            label="Выставить счет"
                                            // onChange={handleChange}
                                            checked={payment === "food"}
                                        />
                                    </Form.Group>

                                </div>

                                <hr className="my-4"/>

                                <Button
                                    className="w-100 btn btn-primary btn-lg"
                                    // variant={"outline-success"}
                                    onClick={click}
                                >
                                    Оформить заказ
                                </Button>
                            </Form>
                        </div>
                    </div>
                </main>
            </Container>
        </div>
    );
};

export default Checkout;