import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { Context } from "../index";
import { Row } from "react-bootstrap";
import BasketItem from "./BasketItem";

const BasketList = observer(() => {
    const { product } = useContext(Context)
    return (
        <Row>
            {product.basketProducts?.length > 0 ? product.basketProducts?.map(product =>
                <BasketItem key={product.id} basketProduct={product} />
            ) : (<p>Корзина пуста</p>)}
        </Row>
    );
});

export default BasketList;