import { $authHost, $host } from "./index";
import jwt_decode from "jwt-decode";

// export const registration = async (email, password) => {
//     const response = await $host.post('admin/registration', {email, password})
//     return response
// }

export const login = async (email, password) => {
    const {data} = await $host.post('api/login', {email, password})
    localStorage.setItem('token', data.token)
    return data
}

// export const check = async () => {
//     // return {info: 0}
//     const {data} = await $authHost.get('api/check' )
//     localStorage.setItem('token', data.token)
//     return jwt_decode(data.token)
// }

export const hasRole = async (role) => {
    try {
        const { data } = await $authHost.post('api/has_role', role)
        return data
    } catch (e) {
        return false
    }
}

export const getStores = async (user) => {
    try {
        const { data } = await $authHost.get('api/v1/stores/list')
        return data
    } catch (e) {
        window.localStorage.removeItem('token');
        user.setToken(null)
        return []
    }
}

export const getStore = async (user) => {
    try {
        const { data } = await $authHost.get('api/v1/stores/user')
        return data
    } catch (e) {
        window.localStorage.removeItem('token');
        user.setToken(null)
        return []
    }
}

export const getPostings = async (user, isActive) => {
    try {
        const { data } = await $authHost.get('api/v1/postings/user?active=' + isActive)
        return data
    } catch (e) {
        window.localStorage.removeItem('token');
        user.setToken(null)
        return []
    }
}

export const getPosting = async (user, postingNumber) => {
    try {
        const { data } = await $authHost.get('api/v1/posting/' + postingNumber)
        return data
    } catch (e) {
        window.localStorage.removeItem('token');
        user.setToken(null)
        return []
    }
}

export const cancelPosting = async (user, postingNumber) => {
    try {
        const { data } = await $authHost.get('api/v1/posting/cancel/' + postingNumber)
        return data
    } catch (e) {
        window.localStorage.removeItem('token');
        user.setToken(null)
        return []
    }
}

export const editPosting = async (user, posting) => {
    try {
        const { data } = await $authHost.post('api/v1/posting/edit', {user, posting})
        return data
    } catch (e) {
        window.localStorage.removeItem('token');
        user.setToken(null)
        return []
    }
}

export const addProductToPosting = async (user, posting, product) => {
    try {
        const { data } = await $authHost.post('api/v1/posting/add_product', {user, posting, product})
        return data
    } catch (e) {
        window.localStorage.removeItem('token');
        user.setToken(null)
        return []
    }
}

export const editProfile = async (user, firstName, lastName, email, phone, city, address, latitude, longitude, storeId) => {
    try {
        const { data } = await $authHost.post('api/v1/user/edit', {firstName, lastName, email, phone, city, address, latitude, longitude, storeId})
        return data
    } catch (e) {
        alert(e)

        // window.localStorage.removeItem('token');
        // user.setToken(null)
        return []
    }
}