import React, {useContext, useEffect, useState} from "react";
import {Context} from "../index";
import {editPosting, getPosting} from "../http/userAPI";
import {useParams} from "react-router-dom";
import {Button, Form, Modal, Row} from "react-bootstrap";
import {RiShoppingBasketFill} from "react-icons/ri";
import {observer} from "mobx-react-lite";
import {getProducts} from "../http/productAPI";
import ProductItem from "../components/ProductItem";

const PostingPage = observer(() => {
    const {user} = useContext(Context)
    const {product} = useContext(Context)

    const [posting, setPosting] = useState({products: []})
    const {postingNumber} = useParams()
    const [localReload, setLocalReload] = useState(1)

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        getProducts(product.selectedFolder.id, user, product).then(data => product.setProducts(data))
    }, [product.search])

    const alertPlaceholder = document.getElementById('liveAlertPlaceholder')
    const appendAlert = (message, type) => {
        const wrapper = document.createElement('div')
        wrapper.innerHTML = [
            `<div class="alert alert-${type} alert-dismissible fade show" role="alert">`,
            `   <div>${message}</div>`,
            '   <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>',
            '</div>'
        ].join('')

        alertPlaceholder.append(wrapper)
    }

    useEffect(() => {
        getPosting(user, postingNumber).then(data => setPosting(data))
    }, [product.reload])

    useEffect(() => {
    }, [localReload])

    return (
        <div className="container">
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Добавить товар</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form role="search" id="search">
                        <Form.Control className="me-2 rounded-4 mb-3" type="search"
                                      placeholder="Найти, например MSAG1-07HRN1..." aria-label="Search"
                                      value={product.search}
                                      onChange={(e) => {
                                          product.resetFilters()
                                          product.setSelectedFolder({
                                              id: 'f7f487a6-8257-11eb-0a80-0011000ac16e',
                                              name: 'Сплит-системы настенные'
                                          })
                                          product.setPaginationPage(1)
                                          product.setSearch(e.target.value)
                                          console.log(e.target.value)
                                      }}
                        />
                        {product.search !== "" && (
                            <Row className="d-flex">
                                {product.products.map(productItem =>
                                    <ProductItem key={productItem.id} productItem={productItem} modal={true} handleClose={handleClose} />
                                )}
                            </Row>
                        )}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Закрыть
                    </Button>
                </Modal.Footer>
            </Modal>
            <div id="liveAlertPlaceholder"></div>
            <div className="copy-clipboard py-5 text-center">
                <h2>Заказ {posting.number}</h2>
            </div>
            <div className="row container">
                <div className="col-md-7 col-lg-12">
                    <h4 className="mb-3">Товары</h4>
                    <div className="table-responsive">
                        <table className="table table-striped table-sm">
                            <thead>
                            <tr>
                                <th scope="col">Товар</th>
                                <th scope="col">Артикул</th>
                                <th scope="col">Стоимость</th>
                                <th scope="col">Количество</th>
                            </tr>
                            </thead>
                            <tbody>
                            {posting.products.length > 0 && posting.products.map((productItem) => (
                                <tr>
                                    <td>{productItem.name}</td>
                                    <td>{productItem.article}</td>
                                    <td>{productItem.price}</td>
                                    <td>
                                        <Button variant={"outline-danger"} size="sm" disabled={productItem.count <= 1} onClick={() => {
                                            productItem.count--
                                            setLocalReload(localReload + 1)
                                        }}>-</Button>
                                        &nbsp;{productItem.count}&nbsp;
                                        <Button variant={"outline-success"} size="sm" onClick={() => {
                                            productItem.count++
                                            setLocalReload(localReload + 1)
                                        }}>+</Button>
                                        &nbsp;
                                        {posting.products.length > 1 && (
                                        <Button variant={"outline-danger"} size="sm" onClick={() => {
                                            posting.products = posting.products.filter((pr) => pr.article !== productItem.article)
                                            setLocalReload(localReload + 1)
                                        }}><RiShoppingBasketFill/></Button>)}
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                        {localReload > 1 &&
                        <Button variant="warning" onClick={() => {
                            editPosting(user.user, posting);
                            // navigate(HISTORY_ROUTE);
                            appendAlert('Товары успешно обновлены', 'success')
                        }}>
                            Сохранить
                        </Button>
                        }
                        <Button variant="primary" onClick={handleShow}>Добавить товар</Button>
                    </div>
                    <div className="row g-3">
                        <div className="col-sm-4">
                            <h4>Покупатель</h4>
                            <p>{posting.customer}</p>
                        </div>
                        <div className="col-sm-4">
                            <h4>Получатель</h4>
                            <p>{posting.addressee}</p>
                        </div>
                        <div className="col-sm-4">
                            <h4>Номер телефона</h4>
                            <a href={"tel:" + posting.phone} style={{ color:"#939598", "font-size":"0.9rem", "text-align":"right" }} className="me-4">{posting.phone}</a>
                        </div>
                        <div className="col-12">
                            <h4>Адрес доставки</h4>
                            <p>{posting.address}</p>
                        </div>
                        <div className="col-4">
                            <h4>Статус</h4>
                            <p>{posting.status}</p>
                        </div>
                        <div className="col-4">
                            <h4>Принят в обработку</h4>
                            <p>{posting.date}</p>
                        </div>
                        {posting.shipmentDate &&
                            <div className="col-4">
                                <h4>Дата отгрузки</h4>
                                <p>14.06.2024 23:00</p>
                            </div>
                        }
                    </div>
            </div>
        </div>
</div>
)
    ;
});

export default PostingPage;