import React, {useContext, useEffect} from "react";
import {Col, Container, Row} from "react-bootstrap";
import CatalogFilter from "../components/CatalogFilter";
import ProductList from "../components/ProductList";
import CatalogPagination from "../components/CatalogPagination";
import {observer} from "mobx-react-lite";
import {Context} from "..";
import {getCatalog, getFilters, getProducts} from "../http/productAPI";

const Catalog = observer(() => {
    const {product} = useContext(Context)
    const {user} = useContext(Context)

    useEffect(() => {
        getCatalog(user).then(data => product.setFolders(data))
        getFilters(user).then(data => product.setFilters(data))
    }, [product.selectedFolder])

    useEffect(() => {
        getProducts(product.selectedFolder.id, user, product).then(data => product.setProducts(data))
    }, [product.brands, product.model, product.invertor, product.hladagent, product.color,
        product.minSquere, product.maxSquere, product.minPrice, product.maxPrice,
        product.search,
        product.selectedFolder])

    return (
        <Container className="mt-5">
            <Row className="mt-2">
                <Col md={12} lg={9}>
                    <ProductList/>
                </Col>
                {product.selectedFolder.name === 'Сплит-системы настенные' &&
                <Col md={3} className="d-none d-lg-block">
                    <CatalogFilter/>
                </Col>}
            </Row>
            {product.pagination.pageCount > 1 &&
                <CatalogPagination/>}
        </Container>
    );
});

export default Catalog;