import React, {useState, useContext} from "react";
import {Card, Row, Col, Image, Button} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {BASKET_ROUTE, PRODUCT_ROUTE} from "../utils/const";
import {Context} from "..";
import {RiShoppingBasketFill} from "react-icons/ri";

const BasketItem = ({basketProduct}) => {
    const navigate = useNavigate()
    const [count, setCount] = useState(0)
    const {product} = useContext(Context)

    return (
        <Row border={"light"}>
            <Col md={3} className={"mt-3"} onClick={() => navigate(PRODUCT_ROUTE + '/' + basketProduct.id)}
                 style={{cursor: 'pointer'}}>
                <Image width={150} height={120}
                       src={basketProduct.images?.length > 0 ? process.env.REACT_APP_API_URL + "img/" + basketProduct.images[0] : "https://cs6.pikabu.ru/post_img/2015/07/04/10/1436029898_1190099444.jpg"}/>
            </Col>
            <Col md={9}>
                <h4 onClick={() => navigate(PRODUCT_ROUTE + '/' + basketProduct.id)}
                    style={{cursor: 'pointer'}}>{basketProduct.name}</h4>
                <div className="text-danger mt-1">Розничная цена: {basketProduct.price} р</div>
                <div className="text-success">Ваша цена: {basketProduct.price_opt} р</div>
                <div className="text-black-50">В корзине:&nbsp;
                    <Button variant={"outline-danger"} size="sm" onClick={() => {
                        if (basketProduct.price > 0) {
                            setCount(basketProduct.count - 1)
                            product.changeCountBasketProduct(basketProduct, basketProduct.count - 1)
                            navigate(BASKET_ROUTE)
                        }
                    }}>-</Button>
                    &nbsp;{basketProduct.count} шт.&nbsp;
                    <Button variant={"outline-success"} size="sm" onClick={() => {
                        if (basketProduct.price > 0) {
                            setCount(basketProduct.count + 1)
                            product.changeCountBasketProduct(basketProduct, basketProduct.count + 1)
                            navigate(BASKET_ROUTE)
                        }
                    }}>+</Button>
                    &nbsp;
                    <Button variant={"outline-danger"} size="sm" onClick={() => {
                        if (basketProduct.price > 0) {
                            product.changeCountBasketProduct(basketProduct, 0)
                            navigate(BASKET_ROUTE)
                        }
                    }}><RiShoppingBasketFill/></Button>
                </div>
            </Col>
        </Row>
    );
};

export default BasketItem;