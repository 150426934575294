import React from "react";
import logo_gray from '../assets/logo_evo7_gray.svg'
import { useContext } from "react";
import {Row, Col} from "react-bootstrap";
import CatalogFilter from "./CatalogFilter";
import {Context} from "../index";
import {observer} from "mobx-react-lite";

const Footer = observer(() => {
    const {product} = useContext(Context)

    return (
        <>
            <footer className="bd-footer py-4 bg-dark mt-5">
                <div className="container d-flex justify-content-between align-items-center">
                    <div className="mb-2">
                        <a className="active" style={{ color: "white" }} href="/" aria-current="page">
                            <img src={logo_gray} className="logo_header" alt="logo" />
                        </a>
                    </div>
                    <div style={{ color:"#939598", "font-size":"0.9rem", "text-align":"right" }}>
                        <div className="d-flex align-items-center justify-content-end">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone me-2" viewBox="0 0 16 16">
                                <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"/>
                            </svg>
                            <a href="tel:+79377472585" style={{ color:"#939598", "font-size":"0.9rem", "text-align":"right" }} className="me-4">+7 (937) 747-25-85</a>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope me-2" viewBox="0 0 16 16">
                                <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z"/>
                            </svg>
                            <a href="mailto:info@evo7.ru" style={{ color:"#939598", "font-size":"0.9rem", "text-align":"right" }}>info@evo7.ru</a>
                        </div>
                        ©&nbsp;ЭВОСЕМЬ&nbsp;2024.&ensp;Все права защищены.
                    </div>
                </div>
            </footer>

            <div className="sticky-bottom d-block d-lg-none bg-dark py-4">
                <div className="container">
                    <Row>
                        <Col xs={product.selectedFolder.name === 'Сплит-системы настенные' ? 3 : 4}>
                            <a className=" text-center text-light d-flex flex-column align-items-center text-decoration-none"
                               type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar"
                               aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-list mb-2 fs-4" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"/>
                                </svg>
                                Каталог
                            </a>
                        </Col>
                        {product.selectedFolder.name === 'Сплит-системы настенные' &&
                            <Col xs={3}>
                                <a href="#filterNavbar"
                                   className=" text-center text-light d-flex flex-column align-items-center text-decoration-none"
                                   type="button" data-bs-toggle="offcanvas" aria-controls="filterNavbar">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                         className="bi bi-funnel mb-2 fs-4" viewBox="0 0 16 16">
                                        <path
                                            d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2z"/>
                                    </svg>
                                    Фильтр
                                </a>
                            </Col>
                        }
                        <Col xs={product.selectedFolder.name === 'Сплит-системы настенные' ? 3 : 4}>
                            <a href="/basket" className="text-center text-light d-flex flex-column align-items-center text-decoration-none" type="button">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-cart mb-2 fs-4" viewBox="0 0 16 16">
                                    <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5M3.102 4l1.313 7h8.17l1.313-7zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4m7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4m-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2m7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2"/>
                                </svg>
                                Корзина
                                <span className="rounded-5 text-bg-danger d-block py-1 px-2"
                                    style={{ "position": "absolute", "margin-top": "-12px", "margin-left": "32px", "font-size": "0.77rem"}}
                                >0</span>
                            </a>
                        </Col>
                        <Col xs={product.selectedFolder.name === 'Сплит-системы настенные' ? 3 : 4}>
                            <a href="/profile" className="text-center text-light d-flex flex-column align-items-center text-decoration-none" type="button">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person mb-2 fs-4" viewBox="0 0 16 16">
                                    <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z"/>
                                </svg>
                                Профиль
                            </a>
                        </Col>
                    </Row>
                </div>

                <div className="offcanvas offcanvas-start filter-panel" tabIndex="-1" id="filterNavbar" aria-labelledby="filterNavbarLabel">
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" style={{ position: "absolute", padding: "15px", "margin-left": "90%"}}></button>
                    <CatalogFilter/>
                </div>
            </div>
        </>
    );
});

export default Footer;