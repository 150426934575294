import React, {useContext, useState, useEffect} from "react";
import {InputGroup, Form, Button, Container, FloatingLabel} from "react-bootstrap";
import {Context} from "../index";
import {getStore, getStores, editProfile, editPosting} from "../http/userAPI";
import {AddressSuggestions} from "react-dadata";

const Profile = () => {
    const {user} = useContext(Context)
    const [firstName, setFirstName] = useState(user.user.name)
    const [lastName, setLastName] = useState(user.user.lastName)
    const [email, setEmail] = useState(user.user.email)
    const [phone, setPhone] = useState(user.user.phoneNumber)
    const [city, setCity] = useState(user.user.city)
    const [address, setAddress] = useState({value: user.user.address, data: {geo_lat: user.user.latitude, geo_lon: user.user.longitude}})
    const [stores, setStores] = useState([])
    const [storeId, setStoreId] = useState({id: 'c68d733c-e253-11ec-0a80-01540008c7ec'})

    useEffect(() => {
        getStores(user).then(data => setStores(data))
        getStore(user).then(data => setStoreId(data))
    }, [])

    const alertPlaceholder = document.getElementById('liveAlertPlaceholder')
    const appendAlert = (message, type) => {
        const wrapper = document.createElement('div')
        wrapper.innerHTML = [
            `<div class="alert alert-${type} alert-dismissible fade show" role="alert">`,
            `   <div>${message}</div>`,
            '   <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>',
            '</div>'
        ].join('')

        alertPlaceholder.append(wrapper)
    }

    const click = async () => {
        try {
            let data = await editProfile(user, firstName, lastName, email, phone, city, address.value, address.data.geo_lat, address.data.geo_lon, storeId.id);
        } catch (e) {
            alert(e.response.data?.message)
        }

    }

    return (
        <Container style={{"max-width": "960px"}}>
            <div id="liveAlertPlaceholder"></div>

            <main>
                <div className="py-5 text-center">
                    <h2>Оформление заказа</h2>
                    <h1>Профиль</h1>
                    <a href="/history">История заказов</a>
                </div>

                <div className="row g-5">

                    <div className="col-md-7 col-lg-12">
                        <h4 className="mb-3">Редактировать профиль</h4>
                        <Form className="needs-validation" noValidate>
                            <div className="row g-3">
                                <Form.Group className="col-sm-6" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Имя</Form.Label>
                                    <Form.Control type="text" id="firstName" value={firstName} onChange={e => setFirstName(e.target.value)} />
                                </Form.Group>

                                <Form.Group className="col-sm-6" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Фамилия</Form.Label>
                                    <Form.Control type="text" id="lastName" value={lastName} onChange={e => setLastName(e.target.value)} />
                                </Form.Group>

                                <Form.Group className="col-12" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" id="email" value={email} onChange={e => setEmail(e.target.value)} disabled/>
                                </Form.Group>

                                <Form.Group className="col-6" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Город</Form.Label>
                                    <Form.Control type="text" id="city" value={city} onChange={e => setCity(e.target.value)} />
                                </Form.Group>

                                <Form.Group className="col-6" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Телефон получателя</Form.Label>
                                    <Form.Control type="text" id="phone" value={phone} onChange={e => setPhone(e.target.value)} />
                                </Form.Group>

                                <Form.Group className="col-12" controlId="address">
                                    <Form.Label>Адресс доставки по умолчанию</Form.Label>
                                    <AddressSuggestions token="a199d8110c08dd0d938a91b102aefa65fcf11239" value={address} onChange={setAddress} />
                                    {/*<Form.Control type="text" id="address" value={address} onChange={e => setAddress(e.target.value)} />*/}
                                </Form.Group>
                            </div>


                            <hr className="my-4"/>

                            <FloatingLabel controlId="floatingInputGrid" className="h6" label="Склад для перемещения по умолчанию">
                                <Form.Select aria-label="Склад для перемещения по умолчанию" value={storeId.id}
                                             onChange={(e) => {
                                                 setStoreId({id: e.target.value})
                                             }}>
                                    {stores.map((entry) =>
                                        <option value={entry.id} key={entry.id}>{entry.name}</option>
                                    )}
                                </Form.Select>
                            </FloatingLabel>

                            <Button
                                className="w-100 btn btn-primary btn-lg"
                                // variant={"outline-success"}
                                onClick={() => { click().then(r => appendAlert('Профиль успешно обновлен', 'success'))}}
                            >
                                Сохранить
                            </Button>
                        </Form>
                    </div>
                </div>
            </main>
        </Container>
    );
};

export default Profile;