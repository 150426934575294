import React, {useContext, useState, useEffect} from "react";
import {Context} from "..";
import {Card, Button, Form, FloatingLabel} from "react-bootstrap";
import {CHECKOUT_ROUTE, CATALOG_ROUTE, CHECKOUT_DELIVERING_ROUTE} from "../utils/const";
import {useNavigate} from "react-router-dom";
import {createMovePosting} from "../http/productAPI";
import {getStore, getStores, hasRole} from "../http/userAPI";
import data from "bootstrap/js/src/dom/data";

const BasketConfirm = () => {
    const {product} = useContext(Context)
    const {user} = useContext(Context)
    const navigate = useNavigate()
    const [hasRoleShop, setHasRoleShop] = useState(false)
    const [hasRoleOpt, setHasRoleOpt] = useState(false)
    const [stores, setStores] = useState([])
    const [storeId, setStoreId] = useState({id: 'c68d733c-e253-11ec-0a80-01540008c7ec'})

    useEffect(() => {
        getStores(user).then(data => setStores(data))
        getStore(user).then(data => setStoreId(data))
        hasRole("ROLE_SHOP").then(data => setHasRoleShop(data))
        hasRole("ROLE_OPT").then(data => setHasRoleOpt(data))

    }, [])

    const click = async () => {
        try {
            let data = await createMovePosting(user.user, product.basketProducts, storeId);
            if (data.status == 200) {
                product.setBasketProducts([])
                navigate(CATALOG_ROUTE)
            }
            // user.setUser(data)
            // user.setIsAuth(true)
            // user.setToken(data.token)
            // navigate(CATALOG_ROUTE)
        } catch (e) {
            alert(e.response.data?.message)
        }
    }

    return (
        <>{product.basketSum > 0 ? (
            <Card
                className="d-flex flex-column align-items-center"
                style={{width: 300, padding: 10, fontSize: 32, border: '5px solid lightgray'}}
            >

                {hasRoleOpt && (<p className="h4">Итого: {product.basketSum} руб.</p>)}

                {hasRoleOpt && (<Button variant={"outline-success"} onClick={() => navigate(CHECKOUT_ROUTE)}>
                    Перейти к оформлению
                </Button>)}


                {hasRoleShop && (<Form style={{margin: 10}}>
                    <FloatingLabel controlId="floatingInputGrid" className="h6" label="Переместить на">
                        <Form.Select aria-label="Переместить на" value={storeId.id}
                                     onChange={(e) => {
                                         setStoreId({id: e.target.value})
                                     }}>
                            {stores.map((entry) =>
                                <option value={entry.id} key={entry.id}>{entry.name}</option>
                            )}
                        </Form.Select>
                    </FloatingLabel>
                    <Button
                        // className="w-100 btn btn-primary btn-lg"
                        variant={"outline-warning"}
                        onClick={click}
                    >
                        Оформить перемещение в магазин
                    </Button>

                </Form>
                )}
                {hasRoleShop && (<Button variant={"outline-success"} onClick={() => navigate(CHECKOUT_DELIVERING_ROUTE)}>
                    Оформить доставку до клиента
                </Button>)}
            </Card>
        ) : ('')}</>
    );
};

export default BasketConfirm;