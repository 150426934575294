import React, {useEffect, useState, useContext} from "react";
import {Context} from "..";
import {Button, Col, Container, Image, Row, Carousel} from "react-bootstrap";
import {getProduct} from "../http/productAPI";
import {useParams} from "react-router-dom";
import {RiShoppingBasketFill} from "react-icons/ri";

const ProductPage = () => {
    const [index, setIndex] = useState(0);
    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    const {user} = useContext(Context)
    const {product} = useContext(Context)
    const [productItem, setProductItem] = useState({})
    const [count, setCount] = useState(0)
    const {id} = useParams()
    useEffect(() => {
        getProduct(id, user).then(data => setProductItem(data))
        product.basketProducts?.map((bp) => (bp.id === id ? setCount(bp.count) : ''))
    }, [])

    console.log(productItem)
    return (
        <Container className="mt-5">
            <nav aria-label="breadcrumb" className="mb-4">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#">Каталог</a></li>
                    <li className="breadcrumb-item"><a href="#">Сплит-системы настенные</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Сплит-система Ecoletta DC Inverter
                        ECO07AN/LETTA07AN
                    </li>
                </ol>
            </nav>
            <Row>
                <Col md={6}>
                    <Carousel activeIndex={index} onSelect={handleSelect} className="carousel-dark">
                        {productItem.images?.length > 0 ? productItem.images?.map(image =>
                                <Carousel.Item key={image} className="container text-center">
                                    <Image height={300} src={process.env.REACT_APP_API_URL_PROD + "img/" + image}/>
                                </Carousel.Item>
                            ) :
                            <Carousel.Item className="container text-center">
                                <Image height={300}
                                       src="https://cs6.pikabu.ru/post_img/2015/07/04/10/1436029898_1190099444.jpg"/>
                            </Carousel.Item>
                        }
                    </Carousel>
                </Col>
                <Col md={6}>
                    <h2 className="fs-4 fw-semibold mb-3 text-center text-md-start">{productItem.name}</h2>
                    <Row className="d-flex flex-column align-items-center align-items-md-start">
                        <p className="text-danger mb-0">Рекомендуемая розничная цена: {productItem.price}₽</p>
                        <p className="text-success fs-4 fw-semibold">Ваша цена: {productItem.price_opt}₽</p>
                        <div className="text-black-50 mb-4">
                            В наличии — {productItem.countour} шт.
                            <br/>
                            Поставка 1-2 дня — {productItem.countss} шт.
                            <br/>
                            Поставка 7 дней — {productItem.countsit} шт.
                        </div>
                        {count === 0 ? (
                            <Button variant={"outline-primary"} className="rounded-4 mt-2 fw-bold"
                                    onClick={() => {
                                        if (productItem.price > 0) {
                                            product.addBasketProduct(productItem)
                                            setCount(count + 1)
                                        }
                                    }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                     className="me-2 bi bi-cart-plus-fill" viewBox="0 0 16 16">
                                    <path
                                        d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0m7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0M9 5.5V7h1.5a.5.5 0 0 1 0 1H9v1.5a.5.5 0 0 1-1 0V8H6.5a.5.5 0 0 1 0-1H8V5.5a.5.5 0 0 1 1 0"></path>
                                </svg>
                                В корзину
                            </Button>
                        ) : (
                            <div>В корзине:

                                <Button variant={"outline-danger"} size="sm" onClick={() => {
                                    if (productItem.price > 0) {
                                        setCount(count - 1)
                                        product.changeCountBasketProduct(productItem, count - 1)
                                    }
                                }}>-</Button>
                                &nbsp;{count}&nbsp;
                                <Button variant={"outline-success"} size="sm" onClick={() => {
                                    if (productItem.price > 0) {
                                        setCount(count + 1)
                                        product.changeCountBasketProduct(productItem, count + 1)
                                    }
                                }}>+</Button>
                                &nbsp;
                                <Button variant={"outline-danger"} size="sm" onClick={() => {
                                    if (productItem.price > 0) {
                                        product.changeCountBasketProduct(productItem, 0)
                                        setCount(0)
                                    }
                                }}><RiShoppingBasketFill/></Button>
                            </div>
                        )}
                    </Row>
                </Col>
            </Row>
            <ul className="nav nav-tabs mt-4" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="home-tab" data-bs-toggle="tab"
                            data-bs-target="#home-tab-pane"
                            type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">Описание
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="profile-tab" data-bs-toggle="tab"
                            data-bs-target="#profile-tab-pane"
                            type="button" role="tab" aria-controls="profile-tab-pane"
                            aria-selected="false">Характеристики
                    </button>
                </li>
            </ul>
            <div className="tab-content mt-4" id="myTabContent">
                <div className="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab"
                     tabIndex="0">
                    {productItem.description}
                </div>
                <div className="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab"
                     tabIndex="0">
                    {productItem.attributes?.map((info, index) =>
                        <Row key={info.id}
                             style={{background: index % 2 === 0 ? 'lightgray' : 'transparent', padding: 10}}>
                            {info.name}: {info.value}
                        </Row>
                    )}
                </div>
            </div>
        </Container>
    );
};

export default ProductPage;