import Pagination from 'react-bootstrap/Pagination';
import {observer} from "mobx-react-lite";
import React, {useContext, useEffect} from "react";
import {Context} from "../index";
import {getProducts} from "../http/productAPI";

const CatalogPagination = observer(() => {
    const {product} = useContext(Context)
    const {user} = useContext(Context)
    useEffect(() => {
        getProducts(product.selectedFolder.id, user, product).then(data => product.setProducts(data))
    }, [product.pagination.pageNumber])

    const pages = [];
    for (let i = 1; i <= product.pagination.pageCount; i++) {
        if (product.pagination.pageNumber === i)
            pages.push(<Pagination.Item className="mx-1" linkClassName="rounded-0 shadow-none border-0 text-body" active key={i}>{i}</Pagination.Item>)
        else
            pages.push(<Pagination.Item className="mx-1" linkClassName="rounded-0 shadow-none border-0 text-body" key={i} onClick={() => {product.setPaginationPage(i)}}>{i}</Pagination.Item>)
    }

    return (
        <div className="col-12 text-center">
            <nav aria-label="Page navigation example">
                <Pagination className="justify-content-center">
                    {/*<Pagination.First onClick={() => {product.setPaginationPage(1)}} />*/}
                    {/*{product.pagination.pageNumber > 1 && <Pagination.Prev onClick={() => {product.setPaginationPage(product.pagination.pageNumber - 1)}} />}*/}
                    {/*{pages}*/}
                    {/*{product.pagination.pageNumber < product.pagination.pageCount && <Pagination.Next onClick={() => {product.setPaginationPage(product.pagination.pageNumber + 1)}} />}*/}
                    {/*<Pagination.Last onClick={() => {product.setPaginationPage(product.pagination.pageCount)}} />*/}

                    {product.pagination.pageNumber > 1 && <Pagination.Item className="mx-1" linkClassName="rounded-4 shadow-none text-body" onClick={() => {product.setPaginationPage(product.pagination.pageNumber - 1)}}>Назад</Pagination.Item>}
                    {pages}
                    {product.pagination.pageNumber < product.pagination.pageCount && <Pagination.Item className="mx-1 " linkClassName="rounded-4 shadow-none text-body" onClick={() => {product.setPaginationPage(product.pagination.pageNumber + 1)}}>Вперед</Pagination.Item>}
                </Pagination>
            </nav>
        </div>
        //     <ul className="pagination justify-content-center">
        //         <li className="page-item mx-1"><a className="page-link rounded-4 shadow-none" href="#">Назад</a></li>
        //         <li className="page-item mx-1"><a className="page-link border-0 shadow-none" href="#">1</a></li>
        //         <li className="page-item mx-1"><a className="page-link border-0 shadow-none" href="#">2</a></li>
        //         <li className="page-item mx-1"><a className="page-link border-0 shadow-none" href="#">3</a></li>
        //         <li className="page-item mx-1"><a className="page-link border-0 shadow-none" href="#">4</a></li>
        //         <li className="page-item mx-1"><a className="page-link border-0 shadow-none" href="#">5</a></li>
        //         <li className="page-item mx-1"><a className="page-link rounded-4 shadow-none" href="#">Вперед</a></li>
        //     </ul>
    );
});

export default CatalogPagination;