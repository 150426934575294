import {observer} from "mobx-react-lite";
import React, {useContext, useEffect} from "react";
import {Context} from "../index";
import {Card, Row, Col, Form, Accordion, Button} from "react-bootstrap";

const CatalogFilter = observer(() => {
    const {product} = useContext(Context)

    return (
        <Row xs="1" className="px-1 py-2 p-xl-3 d-flex rounded-4 filter-panel">
            <div className="fw-bold fs-6 mb-3">Фильтры</div>

            <Accordion className="border-0" alwaysOpen>
                {product.filters.map(filter =>
                    filter.type === 'select' ? (
                            <Accordion.Item eventKey={filter.id}>
                                <Accordion.Button className="rounded-4">{filter.name}</Accordion.Button>
                                <Accordion.Body>
                                    <Form>
                                        {filter.name === 'Бренд' &&
                                            filter.options.map((option, index) =>
                                                <Form.Check
                                                    type="checkbox"
                                                    label={option}
                                                    value={option}
                                                    checked={product.brands.indexOf(option) !== -1}
                                                    onChange={(e) => {
                                                        let arr = [].concat(product.brands);
                                                        if (arr.indexOf(e.target.value) !== -1) {
                                                            arr = arr.filter((b) => b !== e.target.value)
                                                        } else {
                                                            arr.push(e.target.value)
                                                        }
                                                        product.setBrands(arr)
                                                        product.setSearch("")
                                                        product.setPaginationPage(1)
                                                    }}
                                                />
                                            )
                                        }
                                        {filter.name === 'Инвертор' &&
                                            filter.options.map((option, index) =>
                                                <Form.Check
                                                    type="checkbox"
                                                    label={option}
                                                    value={option}
                                                    checked={product.invertor.indexOf(option) !== -1}
                                                    onChange={(e) => {
                                                        let arr = [].concat(product.invertor);
                                                        if (arr.indexOf(e.target.value) !== -1) {
                                                            arr = arr.filter((b) => b !== e.target.value)
                                                        } else {
                                                            arr.push(e.target.value)
                                                        }
                                                        product.setInvertor(arr)
                                                        product.setSearch("")
                                                        product.setPaginationPage(1)
                                                    }}
                                                />
                                            )
                                        }
                                        {filter.name === 'Хладагент' &&
                                            filter.options.map((option, index) =>
                                                <Form.Check
                                                    type="checkbox"
                                                    label={option}
                                                    value={option}
                                                    checked={product.hladagent.indexOf(option) !== -1}
                                                    onChange={(e) => {
                                                        let arr = [].concat(product.hladagent);
                                                        if (arr.indexOf(e.target.value) !== -1) {
                                                            arr = arr.filter((b) => b !== e.target.value)
                                                        } else {
                                                            arr.push(e.target.value)
                                                        }
                                                        product.setHladagent(arr)
                                                        product.setSearch("")
                                                        product.setPaginationPage(1)
                                                    }}
                                                />
                                            )
                                        }
                                        {filter.name === 'Модельный ряд' &&
                                            filter.options.map((option, index) =>
                                                <Form.Check
                                                    type="checkbox"
                                                    label={option}
                                                    value={option}
                                                    checked={product.model.indexOf(option) !== -1}
                                                    onChange={(e) => {
                                                        let arr = [].concat(product.model);
                                                        if (arr.indexOf(e.target.value) !== -1) {
                                                            arr = arr.filter((b) => b !== e.target.value)
                                                        } else {
                                                            arr.push(e.target.value)
                                                        }
                                                        product.setModel(arr)
                                                        product.setSearch("")
                                                        product.setPaginationPage(1)
                                                    }}
                                                />
                                            )
                                        }
                                        {filter.name === 'Цвет' &&
                                            filter.options.map((option, index) =>
                                                <Form.Check
                                                    type="checkbox"
                                                    label={option}
                                                    value={option}
                                                    checked={product.color.indexOf(option) !== -1}
                                                    onChange={(e) => {
                                                        let arr = [].concat(product.color);
                                                        if (arr.indexOf(e.target.value) !== -1) {
                                                            arr = arr.filter((b) => b !== e.target.value)
                                                        } else {
                                                            arr.push(e.target.value)
                                                        }
                                                        product.setColor(arr)
                                                        product.setSearch("")
                                                        product.setPaginationPage(1)
                                                    }}
                                                />
                                            )
                                        }
                                    </Form>
                                </Accordion.Body>
                            </Accordion.Item>)
                        : (
                            <Accordion.Item eventKey={filter.id}>
                                {/*<Accordion.Header>{filter.name}</Accordion.Header>*/}
                                <Accordion.Button className="rounded-4">{filter.name}</Accordion.Button>
                                <Accordion.Body>
                                    <Form>
                                        {filter.name === 'Площадь помещения' &&
                                            <div className="d-flex flex-row align-items-center">
                                                {/*<Form.Label className="small border-0">{filter.name}</Form.Label>*/}
                                                    <Form.Control size="sm" type="input"
                                                                  placeholder={filter.from}
                                                                  value={product.minSquere}
                                                                  onChange={(e) => {
                                                                      product.setMinSquere(e.target.value)
                                                                      product.setSearch("")
                                                                      product.setPaginationPage(1)
                                                                  }}
                                                    />
                                                <span className="px-3">—</span>
                                                    <Form.Control size="sm" type="input"
                                                                  placeholder={filter.to}
                                                                  value={product.maxSquere}
                                                                  onChange={(e) => {
                                                                      product.setMaxSquere(e.target.value)
                                                                      product.setSearch("")
                                                                      product.setPaginationPage(1)
                                                                  }}
                                                    />
                                            </div>
                                        }
                                        {filter.name === 'Цена' &&
                                            <div className="d-flex flex-row align-items-center">
                                                <Form.Control size="sm" type="input"
                                                              placeholder={filter.from}
                                                              value={product.minPrice}
                                                              onChange={(e) => {
                                                                  product.setMinPrice(e.target.value)
                                                                  product.setSearch("")
                                                                  product.setPaginationPage(1)
                                                              }}
                                                />
                                                <span className="px-3">—</span>
                                                <Form.Control size="sm" type="input"
                                                              placeholder={filter.to}
                                                              value={product.maxPrice}
                                                              onChange={(e) => {
                                                                  product.setMaxPrice(e.target.value)
                                                                  product.setSearch("")
                                                                  product.setPaginationPage(1)
                                                              }}
                                                />
                                                {/**/}                                            </div>
                                        }
                                    </Form>
                                </Accordion.Body>
                            </Accordion.Item>
                        )
                )}
            </Accordion>

            <Button variant="outline-dark" className="p-1 col rounded-4 mb-4" onClick={() => {
                product.resetFilters()
                product.setSelectedFolder({
                    id: 'f7f487a6-8257-11eb-0a80-0011000ac16e',
                    name: 'Сплит-системы настенные'
                })
                product.setPaginationPage(1)
            }}>
                Сбросить фильтры
            </Button>
        </Row>
    );
});

export default CatalogFilter;