import React, {useContext, useState} from 'react';
import {Container, Form, FloatingLabel, Image} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {useNavigate} from "react-router-dom";
import {CATALOG_ROUTE} from "../utils/const";
import {login} from "../http/userAPI";
import {observer} from "mobx-react-lite";
import {Context} from "../index";
import '../css/login.css';
import logo from '../assets/logo_evo7.svg'
import nmb from '../assets/nmb_7.svg'
import slogan from '../assets/slogan_e7.svg'

const Auth = observer(() => {
    const {user} = useContext(Context)
    const navigate = useNavigate()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const click = async () => {
        try {
            let data = await login(email, password);
            user.setUser(data)
            user.setIsAuth(true)
            user.setToken(data.token)
            navigate(CATALOG_ROUTE)
        } catch (e) {
            alert(e.response.data?.message)
        }

    }

    return (
        <div className="body-auth bg-dark">

            <Container
                className="text-center"
            >
                <div className="form-signin w-100 m-auto">

                    <Image src={logo} className="logo_evo7 mb-5" alt="logo"/>
                    {/*<h2 className="h3 mb-3 fw-normal">Авторизация</h2>*/}
                    <Form>
                        <FloatingLabel className="mb-4" label="Email address">
                            <Form.Control
                                className="form-control rounded-4 border border-0"
                                placeholder="Введите ваш email..."
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                            />
                        </FloatingLabel>
                        <FloatingLabel className="mb-4" label="Password">
                        <Form.Control
                            className="form-control rounded-4 border border-0"
                            placeholder="Введите ваш пароль..."
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            type="password"
                        />
                        </FloatingLabel>
                        <Button
                            variant={"primary"}
                            className="w-100 btn-lg rounded-4 border border-0"
                            // variant={"outline-success"}
                            onClick={click}
                        >
                            Войти
                        </Button>
                        <p className="mt-5 mb-3 text-muted">&copy; 2023 - 2024</p>

                    </Form>
                </div>
            </Container>
            <img src={nmb} className="nmb_evo7" alt="nmb_evo7" />
            <img src={slogan} className="slogan_evo7" alt="nmb_evo7" />
        </div>
    );
});

export default Auth;