import React, {useState, useContext} from "react";
import {Button, Card, Col, Image} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import {PRODUCT_ROUTE} from "../utils/const";
import {RiShoppingBasketFill} from "react-icons/ri";
import {Context} from "..";
import {addProductToPosting} from "../http/userAPI";

const ProductItem = ({productItem, modal, handleClose}) => {
    const navigate = useNavigate()
    const [count, setCount] = useState(0)
    const {user} = useContext(Context)
    const {product} = useContext(Context)
    const {postingNumber} = useParams()

    return (
        <Col md={4} xl={3} className="mb-5 d-flex">
            <Card border="0">
                <div className="flex-fill">
                    <Image style={{cursor: 'pointer'}} className="product-img rounded-4"
                        src={productItem.image ? process.env.REACT_APP_API_URL_PROD + "img/" + productItem.image : "https://cs6.pikabu.ru/post_img/2015/07/04/10/1436029898_1190099444.jpg"}
                           // src="https://cs6.pikabu.ru/post_img/2015/07/04/10/1436029898_1190099444.jpg"
                           onClick={() => navigate(PRODUCT_ROUTE + '/' + productItem.id)}/>
                </div>
                <div className="text-danger mt-1 d-flex justify-content-between align-items-center">
                    <div>
                        <span className="me-2">РРЦ</span>{productItem.price}₽
                    </div>
                    <div className="d-flex align-items-center">
                        <div className="text-success fw-bold fs-6">
                            <span className="me-2">ОПТ</span>{productItem.price_opt}₽
                        </div>
                    </div>
                </div>
                <div style={{cursor: 'pointer'}} className="porduct-name my-2 fw-bold fs-6"
                     onClick={() => navigate(PRODUCT_ROUTE + '/' + productItem.id)}>
                    {productItem.name}
                </div>
                <div className="text-black-50">
                    В наличии — {productItem.countour} шт.
                    <br/>
                    Поставка 1-2 дня — {productItem.countss} шт.
                    <br/>
                    Поставка 7 дней — {productItem.countsit} шт.
                </div>
                {modal && (
                    <Button variant={"outline-primary"} className="rounded-4 mt-2 fw-bold" onClick={() => {
                        addProductToPosting(user.user, postingNumber, productItem.id)
                        product.setReload(product.reload + 1)
                        handleClose()
                    }}>Добавить в заказ</Button>
                )}
                {!modal && (product.getBasketCount(productItem) === 0 ? (
                    <Button variant={"outline-primary"} className="rounded-4 mt-2 fw-bold" onClick={() => {
                        if (productItem.price > 0) {
                            product.addBasketProduct(productItem)
                            setCount(count + 1)
                        }
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             className="me-2 bi bi-cart-plus-fill" viewBox="0 0 16 16">
                            <path
                                d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0m7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0M9 5.5V7h1.5a.5.5 0 0 1 0 1H9v1.5a.5.5 0 0 1-1 0V8H6.5a.5.5 0 0 1 0-1H8V5.5a.5.5 0 0 1 1 0"/>
                        </svg>В корзину</Button>
                ) : (
                    <div style={{margin: 'auto'}}>
                        {/*<div style={{margin: 'auto'}}>В корзине:</div>*/}
                        <div style={{margin: 'auto'}}>
                            <Button variant={"outline-danger"} className="rounded-4 mt-2 fw-bold" onClick={() => {
                                if (productItem.price > 0) {
                                    setCount(product.getBasketCount(productItem) - 1)
                                    product.changeCountBasketProduct(productItem, product.getBasketCount(productItem) - 1)
                                }
                            }}>-</Button>
                            &nbsp;&nbsp;{product.getBasketCount(productItem)}&nbsp;&nbsp;
                            <Button variant={"outline-success"} className="rounded-4 mt-2 fw-bold" onClick={() => {
                                if (productItem.price > 0) {
                                    setCount(product.getBasketCount(productItem) + 1)
                                    product.changeCountBasketProduct(productItem, product.getBasketCount(productItem) + 1)
                                }
                            }}>+</Button>
                            &nbsp;
                            <Button variant={"outline-danger"} className="rounded-4 mt-2 fw-bold" onClick={() => {
                                if (productItem.price > 0) {
                                    product.changeCountBasketProduct(productItem, 0)
                                    setCount(0)
                                }
                            }}><RiShoppingBasketFill/></Button>
                        </div>
                    </div>
                ))}
            </Card>
        </Col>
    );
};

export default ProductItem;