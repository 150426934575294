import React, { useContext } from "react";
import { Routes, Route, Navigate } from 'react-router-dom';
import { authRoutes, publicRoutes } from "../routes";
import { CATALOG_ROUTE, LOGIN_ROUTE } from "../utils/const";
import { Context } from "../index";

const AppRouter = () => {
    const {user} = useContext(Context)

    return (
        <Routes>
            {user.token && authRoutes.map(({ path, Component }) => 
                <Route key={path} path={path} Component={Component} exact/>
            )}
            {publicRoutes.map(({ path, Component }) => 
                <Route key={path} path={path} Component={Component} exact/>
            )}
            <Route path="*" element={<Navigate to={LOGIN_ROUTE} />}/>
        </Routes>
    );
};

export default AppRouter;