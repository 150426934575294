import { makeAutoObservable } from "mobx"

export default class ProductStore {
    constructor() {
        this._reload = 1

        // Каталог
        this._products = JSON.parse(window.localStorage.getItem('products')) || []
        this._folders = JSON.parse(window.localStorage.getItem('folders')) || []
        this._selectedFolder = JSON.parse(window.localStorage.getItem('selectedFolder')) || { id: 'ec6bf610-165e-11ee-0a80-14740009ddd2' }
        this._pagination = JSON.parse(window.localStorage.getItem('pagination')) || { pageNumber: 1, pageCount: 1 }

        // Поиск
        this._search = JSON.parse(window.localStorage.getItem('search')) || ""

        // Фильтры
        this._filters = JSON.parse(window.localStorage.getItem('filters')) || [
            { id: 1, name: 'Цена', type: 'input', from: 0, to: 999999 },
            { id: 2, name: 'Бренд', type: 'select', value: 'Не важно', options: [] },
            { id: 3, name: 'Инвертор', type: 'select', value: 'Не важно', options: ['Не важно', 'Да', 'Нет'] },
            { id: 4, name: 'Площадь помещения', type: 'input', from: 0, to: 999 },
            { id: 5, name: 'Хладагент', type: 'select', value: 'Не важно', options: ['Не важно'] },
            { id: 6, name: 'Модельный ряд', type: 'select', value: 'Не важно', options: ['Не важно'] },
            { id: 7, name: 'Цвет', type: 'select', value: 'Не важно', options: ['Не важно'] },
        ]
        this._brands = JSON.parse(window.localStorage.getItem('brands')) || []
        this._model = JSON.parse(window.localStorage.getItem('model')) || []
        this._invertor = JSON.parse(window.localStorage.getItem('invertor')) || []
        this._hladagent = JSON.parse(window.localStorage.getItem('hladagent')) || []
        this._color = JSON.parse(window.localStorage.getItem('color')) || []
        this._minSquere = JSON.parse(window.localStorage.getItem('minSquere')) || 0
        this._maxSquere = JSON.parse(window.localStorage.getItem('maxSquere')) || 999
        this._minPrice = JSON.parse(window.localStorage.getItem('minPrice')) || 0
        this._maxPrice = JSON.parse(window.localStorage.getItem('maxPrice')) || 999999

        // Корзина
        this._basketProducts = JSON.parse(window.localStorage.getItem('basketProducts')) || []
        // this._basketSum = JSON.parse(window.localStorage.getItem('basketSum')) || 0
        makeAutoObservable(this)
    }

    setReload(reload) {
        this._reload = reload
    }
    get reload() {
        return this._reload
    }

    // Каталог
    setProducts(products) {
        this._products = products
        window.localStorage.setItem('products', JSON.stringify(products));
    }
    get products() {
        return this._products
    }

    setFolders(folders) {
        this._folders = folders
        window.localStorage.setItem('folders', JSON.stringify(folders));
    }
    get folders() {
        return this._folders
    }

    setSelectedFolder(selectedFolder) {
        this._selectedFolder = selectedFolder
        window.localStorage.setItem('selectedFolder', JSON.stringify(selectedFolder));
    }
    get selectedFolder() {
        return this._selectedFolder
    }

    setPagination(pagination) {
        this._pagination = pagination
        window.localStorage.setItem('pagination', JSON.stringify(pagination));
    }
    get pagination() {
        return this._pagination
    }
    setPaginationPage(pageNumber) {
        this._pagination.pageNumber = pageNumber
        window.localStorage.setItem('pagination', JSON.stringify(this._pagination));
    }

    // Поиск
    setSearch(search) {
        this._search = search
        window.localStorage.setItem('search', JSON.stringify(search));
    }
    get search() {
        return this._search
    }

    // Фильтры
    setFilters(filters) {
        this._filters = filters
        window.localStorage.setItem('filters', JSON.stringify(filters));
        this.setMinSquere(filters[3].from)
        this.setMaxSquere(filters[3].to)
        this.setMinPrice(filters[0].from)
        this.setMaxPrice(filters[0].to)
    }
    get filters() {
        return this._filters
    }

    setBrands(brands) {
        this._brands = brands
        window.localStorage.setItem('brands', JSON.stringify(brands));
    }
    get brands() {
        return this._brands
    }

    setModel(model) {
        this._model = model
        window.localStorage.setItem('model', JSON.stringify(model));
    }
    get model() {
        return this._model
    }

    setInvertor(invertor) {
        this._invertor = invertor
        window.localStorage.setItem('invertor', JSON.stringify(invertor));
    }
    get invertor() {
        return this._invertor
    }

    setHladagent(hladagent) {
        this._hladagent = hladagent
        window.localStorage.setItem('hladagent', JSON.stringify(hladagent));
    }
    get hladagent() {
        return this._hladagent
    }

    setColor(color) {
        this._color = color
        window.localStorage.setItem('color', JSON.stringify(color));
    }
    get color() {
        return this._color
    }

    setMinSquere(minSquere) {
        this._minSquere = minSquere
        window.localStorage.setItem('minSquere', JSON.stringify(minSquere));
    }
    get minSquere() {
        return this._minSquere
    }
    setMaxSquere(maxSquere) {
        this._maxSquere = maxSquere
        window.localStorage.setItem('maxSquere', JSON.stringify(maxSquere));
    }
    get maxSquere() {
        return this._maxSquere
    }

    setMinPrice(minPrice) {
        this._minPrice = minPrice
        window.localStorage.setItem('minPrice', JSON.stringify(minPrice));
    }
    get minPrice() {
        return this._minPrice
    }
    setMaxPrice(maxPrice) {
        this._maxPrice = maxPrice
        window.localStorage.setItem('maxPrice', JSON.stringify(maxPrice));
    }
    get maxPrice() {
        return this._maxPrice
    }

    resetFilters() {
        this.setBrands([])
        this.setHladagent([])
        this.setModel([])
        this.setInvertor([])
        this.setColor([])
        this.setMinSquere(0)
        this.setMaxSquere(999)
        this.setMinPrice(0)
        this.setMaxPrice(999999)
    }

    // Корзина
    setBasketProducts(basketProducts) {
        this._basketProducts = basketProducts
        window.localStorage.setItem('basketProducts', JSON.stringify(basketProducts));
    }
    get basketProducts() {
        return this._basketProducts
    }
    addBasketProduct(product) {
        product.count = 1
        this._basketProducts.push(product)
        window.localStorage.setItem('basketProducts', JSON.stringify(this._basketProducts));
    }
    getBasketCount(product) {
        var result = this._basketProducts.filter((bp) => (
            bp.id === product.id
        ))
        return result.length > 0 ? result[0].count : 0
    }
    changeCountBasketProduct(product, count) {
        count === 0
            ? this._basketProducts = this._basketProducts.filter((bp) => (
                bp.id !== product.id
            ))
            : this._basketProducts?.forEach((bp) => (
                bp.id === product.id ?
                    bp.count = count :
                    bp.count = bp.count
            ))
        window.localStorage.setItem('basketProducts', JSON.stringify(this._basketProducts));
    }

    // setBasketSum(basketSum) {
    //     this._basketSum = basketSum
    //     window.localStorage.setItem('basketSum', JSON.stringify(basketSum));
    // }
    get basketSum() {
        var  sum = 0;
        this._basketProducts?.forEach((bp) => (
            sum += bp.price * bp.count
        ))
        return sum
    }
}