import React, {useContext} from "react";
import {Context} from "../index";
import {NavLink, useNavigate} from "react-router-dom";
import {BASKET_ROUTE, CATALOG_ROUTE, LOGIN_ROUTE, PROFILE_ROUTE, HISTORY_ROUTE} from "../utils/const";
import {Button, Image, Form, Navbar} from "react-bootstrap";
import {observer} from "mobx-react-lite";
import logo from "../assets/logo_evo7.svg";
import nmb from "../assets/nmb_7.svg";
import CatalogBar from "./CatalogBar";

const NavBar = observer(() => {
    const {user} = useContext(Context)
    const {product} = useContext(Context)
    const navigate = useNavigate()

    return (
        <div>
            <nav bg="dark" data-bs-theme="dark" className="bg-dark py-3">
                <div className="container d-flex justify-content-between align-items-center">

                    <div className="mb-2">
                        <NavLink className="active" style={{color: "white"}}
                                 onClick={() => {
                                     product.setSelectedFolder({id: 'ec6bf610-165e-11ee-0a80-14740009ddd2'})
                                     product.setPaginationPage(1)
                                 }}
                                 to={CATALOG_ROUTE}
                                 aria-current="page">
                            <Image src={logo} className="logo_header" alt="logo"/>
                        </NavLink>
                    </div>

                    <nav className="navbar">
                        <div className="rounded-4">
                            <Button variant="outline-light" className="d-none d-lg-flex me-2 rounded-4 align-items-center ms-5"
                                    type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar"
                                    aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                     className="bi bi-list me-2" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd"
                                          d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"/>
                                </svg>
                                Каталог
                            </Button>
                            <div className="offcanvas offcanvas-start" tabindex="-1" id="offcanvasNavbar"
                                 aria-labelledby="offcanvasNavbarLabel">
                                <div className="offcanvas-header">
                                    <h5 className="offcanvas-title" id="offcanvasNavbarLabel">Каталог</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas"
                                            aria-label="Close"></button>
                                </div>
                                <div className="offcanvas-body">
                                    <CatalogBar/>
                                    <Image src={nmb} className="nmb_evo7" alt="nmb_evo7"/>
                                </div>
                            </div>
                        </div>
                    </nav>

                    <Form className="input-group d-none d-md-flex d-lg-none d-xl-flex flex-fill ms-5" role="search" id="search">
                        <Form.Control className="me-2 rounded-4" type="search"
                                      placeholder="Найти, например MSAG1-07HRN1..." aria-label="Search"
                                      value={product.search}
                                      onClick={(e) => {
                                          product.resetFilters()
                                          product.setSelectedFolder({
                                              id: 'f7f487a6-8257-11eb-0a80-0011000ac16e',
                                              name: 'Сплит-системы настенные'
                                          })
                                          product.setPaginationPage(1)
                                          navigate(CATALOG_ROUTE)
                                      }}
                                      onChange={(e) => {
                                          product.setSearch(e.target.value)
                                      }}
                        />

                        <Button variant="btn" type="button" form="search">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                 className="bi bi-search" viewBox="0 0 16 16">
                                <path
                                    d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
                            </svg>
                        </Button>
                    </Form>

                    <NavLink className="text-body d-none cart-link ms-5 d-lg-flex flex-row align-items-center text-nowrap"
                             to={BASKET_ROUTE}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             className="bi bi-cart-fill  me-1" viewBox="0 0 16 16">
                            <path
                                d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5M5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4m7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4m-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2m7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2"/>
                        </svg>
                        &nbsp;Корзина {product.basketProducts.length}</NavLink>

                    <NavLink className="text-body d-none cart-link ms-5 d-lg-flex flex-row align-items-center text-nowrap" to={HISTORY_ROUTE}>Заказы</NavLink>

                    <NavLink className="text-body d-none d-lg-flex flex-row align-items-center text-nowrap ms-5"
                             to={PROFILE_ROUTE}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             className="bi bi-person-fill  me-2" viewBox="0 0 16 16">
                            <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
                        </svg>
                        {user.user.lastName} {user.user.name[0]}.</NavLink>

                    <Button variant="outline-light" className="rounded-4 ms-5 d-none d-lg-flex flex-row align-items-center text-nowrap" onClick={() => {
                        // localStorage.removeItem('token')
                        localStorage.clear()
                        user.setToken(null)
                        navigate(LOGIN_ROUTE)
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             className="bi bi-box-arrow-right me-2" viewBox="0 0 16 16">
                            <path fill-rule="evenodd"
                                  d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0z"/>
                            <path fill-rule="evenodd"
                                  d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z"/>
                        </svg>
                        &nbsp;Выйти
                    </Button>

                </div>
            </nav>
        </div>
    );
});

export default NavBar;