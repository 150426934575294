import { makeAutoObservable } from "mobx"

export default class UserStore {
    constructor() {
        this._isAuth = JSON.parse(window.localStorage.getItem('isAuth')) || false
        this._user = JSON.parse(window.localStorage.getItem('user')) || {}
        this._token = JSON.parse(window.localStorage.getItem('token')) || null
        makeAutoObservable(this)
    }

    setIsAuth(bool) {
        this._isAuth = bool
        window.localStorage.setItem('isAuth', JSON.stringify(bool));
    }
    setUser(user) {
        this._user = user
        window.localStorage.setItem('user', JSON.stringify(user));
    }
    setToken(token) {
        this._token = token
        window.localStorage.setItem('token', JSON.stringify(token));
    }

    get isAuth() {
        return this._isAuth
    }
    get user() {
        return this._user
    }
    get token() {
        return this._token
    }
}