// import Admin from "./pages/Admin";
import Auth from "./pages/Auth";
import Basket from "./pages/Basket";
import Catalog from "./pages/Catalog";
import Checkout from "./pages/Checkout";
import ProductPage from "./pages/ProductPage";
import PostingPage from "./pages/PostingPage";
import Profile from "./pages/Profile";
import History from "./pages/History";
import ToDelivering from "./pages/ToDelivering";
import Map from "./pages/Map";

import {
    // ADMIN_ROUTE,
    BASKET_ROUTE,
    PRODUCT_ROUTE,
    CATALOG_ROUTE,
    LOGIN_ROUTE,
    PROFILE_ROUTE,
    HISTORY_ROUTE,
    POSTING_ROUTE,
    // REGISTRATION_ROUTE,
    CHECKOUT_DELIVERING_ROUTE,
    CHECKOUT_ROUTE,
    MAP_ROUTE
} from "./utils/const";

export const authRoutes = [
    {
        path: CATALOG_ROUTE,
        Component: Catalog
    },
    {
        path: PRODUCT_ROUTE + '/:id',
        Component: ProductPage
    },
    {
        path: BASKET_ROUTE,
        Component: Basket
    },
    {
        path: CHECKOUT_ROUTE,
        Component: Checkout
    },
    {
        path: CHECKOUT_DELIVERING_ROUTE,
        Component: ToDelivering
    },
    {
        path: PROFILE_ROUTE,
        Component: Profile
    },
    {
        path: HISTORY_ROUTE,
        Component: History
    },
    {
        path: POSTING_ROUTE + '/:postingNumber',
        Component: PostingPage
    },
    // {
    //     path: ADMIN_ROUTE,
    //     Component: Admin
    // },
    // {
    //     path: BASKET_ROUTE,
    //     Component: Basket
    // }
]

export const publicRoutes = [
    {
        path: LOGIN_ROUTE,
        Component: Auth
    },
    {
        path: MAP_ROUTE,
        Component: Map
    },
    // {
    //     path: REGISTRATION_ROUTE,
    //     Component: Auth
    // },
    
]