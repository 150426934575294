export const ADMIN_ROUTE = '/admin'
export const LOGIN_ROUTE = '/login'
export const PROFILE_ROUTE = '/profile'
export const HISTORY_ROUTE = '/history'
export const POSTING_ROUTE = '/posting'
export const REGISTRATION_ROUTE = '/registration'
export const CATALOG_ROUTE = '/'
export const PRODUCT_ROUTE = '/product'
export const BASKET_ROUTE = '/basket'
export const CHECKOUT_ROUTE = '/checkout'
export const CHECKOUT_DELIVERING_ROUTE = '/to_delivering'
export const MAP_ROUTE = '/map'