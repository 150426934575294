import {observer} from "mobx-react-lite";
import React, {useContext} from "react";
import {Context} from "../index";
import {Button, Form, Nav} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {CATALOG_ROUTE} from "../utils/const";

const CatalogBar = observer(() => {
    const {product} = useContext(Context)
    const navigate = useNavigate()

    return (

        <Nav className="navbar-nav justify-content-end flex-grow-1 pe-3">
            <Form className="d-block d-md-none d-lg-block d-xl-none mb-4" role="search" id="search">
                <Form.Group className="input-group">
                    <Form.Control className="me-2 rounded-4" type="search"
                                  placeholder="Найти, например MSAG1-07HRN1..." aria-label="Search"
                                  value={product.search}
                                  onClick={(e) => {
                                      product.resetFilters()
                                      product.setSelectedFolder({
                                          id: 'f7f487a6-8257-11eb-0a80-0011000ac16e',
                                          name: 'Сплит-системы настенные'
                                      })
                                      product.setPaginationPage(1)
                                      navigate(CATALOG_ROUTE)
                                  }}
                                  onChange={(e) => {
                                      product.setSearch(e.target.value)
                                  }}
                    />

                    <Button variant="btn" type="button" form="search">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             className="bi bi-search" viewBox="0 0 16 16">
                            <path
                                d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
                        </svg>
                    </Button>
                </Form.Group>
            </Form>
            {product.folders.map(folder =>
                (
                    <Nav.Item key={folder.id}>
                        <Nav.Link
                            style={{cursor: 'pointer'}}
                            active={folder.id === product.selectedFolder.id}
                            onClick={() => product.setSelectedFolder(folder)}
                            to={CATALOG_ROUTE}
                            href={"/"}
                        >
                            {folder.name}
                        </Nav.Link>
                    </Nav.Item>
                ))}
        </Nav>
    );
});

export default CatalogBar;