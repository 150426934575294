import { $authHost, $host } from "./index";

export const getCatalog = async (user) => {
    try {
        const { data } = await $authHost.get('api/v1/catalog')
        return data
    } catch (e) {
        window.localStorage.removeItem('token');
        user.setToken(null)
        return []
    }
}

export const getProducts = async (id, user, product) => {
    try {
        const { data } = await $authHost.post('api/v1/realproductsfolder/' + id, {product})
        product.setPagination(data.pagination)
        return data.realProducts
    } catch (e) {
        // window.localStorage.removeItem('token');
        localStorage.clear()
        user.setToken(null)
        return []
    }
}

export const getProduct = async (id, user) => {
    try {
        const { data } = await $authHost.get('api/v1/realproducts/' + id)
        return data
    } catch (e) {
        window.localStorage.removeItem('token');
        user.setToken(null)
        return []
    }
}

export const getFilters = async (user) => {
    try {
        const { data } = await $authHost.get('api/v1/catalog/filters')
        return data
    } catch (e) {
        window.localStorage.removeItem('token');
        user.setToken(null)
        return []
    }
}

export const createPosting = async (user, products, firstName, lastName, phone, address, price, comment, nds, payment) => {
    try {
        const { data } = await $authHost.post('api/v1/posting/create', {user, products, firstName, lastName, phone, address, price, comment, nds, payment})
        return data
    } catch (e) {
        alert(e)

        // window.localStorage.removeItem('token');
        // user.setToken(null)
        return []
    }
}

export const createMovePosting = async (user, products, store) => {
    try {
        const { data } = await $authHost.post('api/v1/posting/move', {user, products, store})
        return data
    } catch (e) {
        alert(e)

        // window.localStorage.removeItem('token');
        // user.setToken(null)
        return []
    }
}

export const createDelivering = async (user, products, firstName, lastName, phone, address, latitude, longitude, price, comment, store) => {
    try {
        const { data } = await $authHost.post('api/v1/delivering/create', {user, products, firstName, lastName, phone, address, latitude, longitude, price, comment, store})
        return data
    } catch (e) {
        alert(e)

        // window.localStorage.removeItem('token');
        // user.setToken(null)
        return []
    }
}