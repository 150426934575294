import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import BasketList from "../components/BasketList";
import BasketConfirm from "../components/BasketConfirm";

const Basket = () => {
    return (
        <Container>
            <Row className="mt-2">
                <Col md={9}>
                    <BasketList />
                </Col>
                <Col md={3}>
                    <BasketConfirm />
                </Col>
            </Row>
        </Container>
    );
};

export default Basket;